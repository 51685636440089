import { useState } from '#app'
import type { FragmentType } from '~/resources/graphql'
import { getFragmentData, graphql } from '~/resources/graphql'
import { computed, readonly, watch } from 'vue'
import { useQuery, useQueryClient } from '@tanstack/vue-query'
import { useRpQuery } from '~/composables/graphql'
import { useActiveFacility } from '~/composables/use-active-facility'
import type { ViewerCartFragmentFragment } from '~/resources/graphql/graphql'
import { useRouteLink } from '~/composables/routing'
import { useRouter } from 'vue-router'
import { useSetViewer, useViewer } from '~/composables/use-login'
import { useLayoutMode } from '~/composables/use-layout-mode'

// Global state for if the cart is visible (landing page)
export function useCartVisible() {
    const cartVisible = useState('cartVisible', () => false)
    return readonly(cartVisible)
}

export function setCartVisible(newValue: boolean) {
    const cartVisible = useState('cartVisible', () => false)
    cartVisible.value = newValue
}

export function useRedirectOnContractDelete(
    contractIdFunction: () => string | undefined
) {
    const { facilityHomeLink } = useRouteLink()
    const lastDeleteContractId = useLastDeletedContractId()
    const router = useRouter()
    const activeFacility = useActiveFacility()
    watch(lastDeleteContractId, newValue => {
        const contractId = contractIdFunction()
        if (contractId !== undefined && contractId === newValue) {
            router.push(facilityHomeLink(activeFacility.value.slug))
        }
    })
}

export function setLastDeletedContractId(newValue: string) {
    const lastDeletedContractId = useState('lastDeletedContractId', () => '')
    lastDeletedContractId.value = newValue
}

export function useLastDeletedContractId() {
    const lastDeleted = useState('lastDeletedContractId', () => '')
    return readonly(lastDeleted)
}

// global state for the cart data
type CartData = ViewerCartFragmentFragment | undefined
export function useCartData() {
    const cartData = useState<CartData>('cartData', () => undefined)
    return readonly(cartData)
}

export function useSetCartData(): (
    newValue: FragmentType<typeof ViewerCartFragment> | undefined
) => void {
    const cartData = useState<CartData>('cartData', () => undefined)
    const { isLightbox } = useLayoutMode()
    return (newValue: FragmentType<typeof ViewerCartFragment> | undefined) => {
        let previousCount = 0
        if (cartData.value !== undefined) {
            previousCount = cartData.value.itemCount
        }

        cartData.value =
            newValue === undefined
                ? undefined
                : getFragmentData(ViewerCartFragment, newValue)

        if (
            isLightbox.value &&
            previousCount !== (cartData.value?.itemCount ?? 0)
        ) {
            parent.postMessage(
                'RPHQ::1::' +
                    JSON.stringify({
                        key: 'refresh-cart-count'
                    }),
                '*'
            )
        }
    }
}

export const ViewerCartFragment = graphql(/* GraphQL */ `
    fragment ViewerCartFragment on Cart {
        itemCount
        ...CartSidebarViewerCartFragment
        ...DuplicateContractCartFragment
    }
`)

const CartQueryKey = 'ViewerCartQuery'
export function useInvalidateCartQuery() {
    const queryClient = useQueryClient()
    return () => {
        return queryClient.invalidateQueries({ queryKey: [CartQueryKey] })
    }
}

export async function useViewerCartQuery() {
    const activeFacility = useActiveFacility()
    // Refetch when viewer changes
    const viewer = useViewer()
    const setViewer = useSetViewer()
    const customerId = computed(() => viewer.value?.customerId)
    const facilityId = computed(() => activeFacility.value.id)
    const setCartData = useSetCartData()

    const query = useRpQuery()
    const response = useQuery({
        queryKey: [CartQueryKey, customerId, facilityId],
        placeholderData: previousData => previousData,
        queryFn: () =>
            query(
                graphql(/* GraphQL */ `
                    query ViewerCartQuery($facilityId: ID!) {
                        viewer {
                            cart(facilityId: $facilityId) {
                                ...ViewerCartFragment
                            }
                        }
                    }
                `),
                { facilityId: facilityId.value }
            )
    })

    watch(
        response.data,
        newValue => {
            if (newValue !== undefined) {
                if (newValue.viewer === null) {
                    setViewer(undefined)
                }
            }
            setCartData(newValue?.viewer?.cart)
        },
        { immediate: true }
    )

    await response
        .suspense()
        .then(response => setCartData(response.data?.viewer?.cart))

    return response
}
