import { useState } from '#app'
import { useDialog } from 'primevue/usedialog'
import LoginModal from '~/components/Verification/LoginModal.vue'
import { readonly } from 'vue'
import type { FragmentType } from '~/resources/graphql'
import { getFragmentData, graphql } from '~/resources/graphql'
import { useRpQuery } from '~/composables/graphql'
import { useMutation, useQuery } from '@tanstack/vue-query'
import { useSetCartData } from '~/composables/use-cart'
import { useRouter } from 'vue-router'
import {
    ActiveFacility,
    ActiveFacilityFragment,
    useActiveFacility
} from '~/composables/use-active-facility'
import { useToast } from 'primevue/usetoast'
import { useRouteLink } from '~/composables/routing'
import { useI18n } from 'vue-i18n'

type ViewerData = {
    id: string
    customerId: string
    name: string
    firstName: string
    homeFacility: ActiveFacility
}

const ViewerFragment = graphql(/* GraphQL */ `
    fragment ViewerFragment on Profile {
        id
        primaryCustomer {
            id
            commaName
            firstName
            homeFacility {
                ...ActiveFacilityFragment
            }
        }
    }
`)

export function useViewer() {
    const viewer = useState<ViewerData | undefined>('viewer')
    return readonly(viewer)
}

export async function provideViewer() {
    const query = useRpQuery({ orgLevel: true })
    const { data, suspense } = useQuery({
        queryKey: ['ViewerProviderQuery'],
        queryFn: () =>
            query(
                graphql(/* GraphQL */ `
                    query ViewerProviderQuery {
                        viewer {
                            ...ViewerFragment
                        }
                    }
                `)
            ),
        retry: false
    })

    const setViewer = useSetViewer()
    const response = await suspense()

    if (data.value !== undefined) {
        if (data.value.viewer !== null) {
            setViewer(data.value.viewer)
        } else {
            setViewer(undefined)
        }
    }

    return response
}

export function useSetViewer() {
    const viewer = useState<ViewerData | undefined>('viewer')
    // const queryClient = useQueryClient()

    return (newValue: FragmentType<typeof ViewerFragment> | undefined) => {
        if (newValue === undefined) {
            viewer.value = undefined
            // Completely clear the query cache
            // queryClient.clear()
        } else {
            const viewerData = getFragmentData(ViewerFragment, newValue)
            viewer.value = {
                id: viewerData.id,
                customerId: viewerData.primaryCustomer.id,
                name: viewerData.primaryCustomer.commaName,
                firstName: viewerData.primaryCustomer.firstName,
                homeFacility: getFragmentData(
                    ActiveFacilityFragment,
                    viewerData.primaryCustomer.homeFacility
                )
            }
        }
    }
}

export function useShowLoginModal(): (
    showGuest: boolean
) => Promise<string | undefined> {
    const dialog = useDialog()
    const viewer = useViewer()
    const activeFacility = useActiveFacility()
    const { t } = useI18n()

    return (showGuest = false) => {
        return new Promise(resolve => {
            let useGuest = false
            const instance = dialog.open(LoginModal, {
                props: {
                    header: t('signInOrRegister'),
                    modal: true,
                    pt: {
                        root: {
                            class: 'w-full h-max-md:h-full max-h-full !rounded-none md:h-auto md:!w-[40rem]'
                        },
                        header: {
                            class: '!rounded-none'
                        },
                        content: {
                            class: '!rounded-none flex-1 '
                        }
                    },
                    ptOptions: {
                        mergeProps: true
                    }
                },
                data: {
                    // Use strict equals because if you don't provide a parameter it doesn't seem to work
                    showGuest: showGuest === true
                },
                emits: {
                    // When we emit close, close the dialog, which will resolve the promise
                    onClose: () => {
                        instance.close()
                    },
                    onGuest: () => {
                        useGuest = true
                        instance.close()
                    }
                },
                onClose: () => {
                    if (useGuest) {
                        resolve(activeFacility.value.organization.onlineGuestId)
                    } else {
                        resolve(viewer.value?.customerId)
                    }
                }
            })
        })
    }
}

export function useLogoutMutation() {
    const query = useRpQuery()
    return useMutation({
        mutationFn: () =>
            query(
                graphql(/** @lang GraphQL */ `
                    mutation Logout {
                        logout
                    }
                `)
            )
    })
}

export function useSignOut() {
    const { facilityHomeLink } = useRouteLink()
    const { mutate, isPending: isSigningOut } = useLogoutMutation()
    const setViewer = useSetViewer()
    const setCartData = useSetCartData()
    const router = useRouter()
    const activeFacility = useActiveFacility()
    const toast = useToast()
    function signOut() {
        mutate(undefined, {
            onSuccess: async data => {
                if (data.logout === true) {
                    await router.push(
                        facilityHomeLink(activeFacility.value.slug)
                    )
                    setCartData(undefined)
                    setViewer(undefined)
                    // TODO: toast shows up briefly in profile instead of on the facility home page
                    toast.add({
                        severity: 'info',
                        summary: 'Sign Out Complete',
                        life: 3000
                    })
                } else {
                    throw new Error()
                }
            }
        })
    }

    return {
        signOut,
        isSigningOut
    }
}
