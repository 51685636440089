/**
 * Generate a random string
 *
 * @param prefix Prefix separated by underscore (if provided)
 */
export function randomString(prefix = ''): string {
    let str =
        Math.random().toString(36).replace('0.', '') +
        Math.random().toString(36).replace('0.', '')

    // If our random string doesn't start with a character, prefix an `a` because sometimes we use
    // this for DOM IDs
    if (!/^[A-Za-z]+/.test(str)) {
        str = 'a' + str
    }

    return (prefix !== '' ? prefix + '_' : '') + str
}
